import { JsonExportable } from "@phoenix/gapicon/shared/classes/json-exportable";

export class TenantJob implements JsonExportable {
    public tenantId: string = undefined;
    public tenantName: string = undefined;
    public jobType: string = undefined;
    public interval: string = undefined;
    public tenantDescription: string = undefined;
    public isActive: boolean = undefined;

    public constructor(tenantJob?: Partial<TenantJob>) {
        Object.assign(this, tenantJob);
    }

    public toJSON(): Object {
        return {
            tenantId: this.tenantId,
            tenantName: this.tenantName,
            jobType: this.jobType,
            interval: this.interval,
            tenantDescription: this.tenantDescription,
            isActive: this.isActive
        };
    }
}
