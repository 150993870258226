import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { format, parseISO } from 'date-fns';
import * as _ from 'lodash-es';
import { v4 as uuid } from 'uuid';

import { AssetDto } from '../../gapicon/asset/dto/asset-dto';
import { AssetTags } from '../../gapicon/asset/dto/asset-tags';
import { MeasurementValueDto } from '../../gapicon/dto.module';
import { MeasurementTypeDto } from '../../gapicon/measurement-type/dto/measurement-type-dto';
import { MeasurementValueTypeDto } from '../../gapicon/measurement-type/dto/measurement-value-type-dto';
import { ValueTypeDto } from '../../gapicon/measurement-type/dto/value-type-dto.enum';
import { MeasurementCorrection } from '../../gapicon/measurement/dto/measurement-correction.enum';
import { MeasurementDto } from '../../gapicon/measurement/dto/measurement-dto';
import { SharedService } from '../../gapicon/shared/services/shared.service';
import { PhoenixSection } from "../../components/phoenix-detail-view/classes/phoenix-section";
import { MeasurementValueEnrichedDto } from '@phoenix/gapicon/measurement/dto/measurement-value-enriched-dto';

@Injectable()
export class PhoenixSharedService {

  public constructor(
    private gapiconSharedService: SharedService,
  ) {
  }

  public convertMeasurementObjectToStringWithUnit(measurementValueDto: MeasurementValueDto): string {
    return `${measurementValueDto.value}${measurementValueDto.unit}`;
  }

  public convertMeasurementValueToCorrectionString(measurementValues: MeasurementValueDto[], i18nPrefix: string): string {
    return this.convertActionsToString(MeasurementCorrection, measurementValues, i18nPrefix);
  }

  public generateFileName(date: Date, asset: AssetDto): string {
    const assetName = asset ? asset.name : 'root';
    return `${format(date, 'yyyy-MM-dd')}_${assetName}`;
  }

  public generateFileNameWithCurrentName(date: Date, currentName: string): string {
    const assetName = currentName ? currentName : 'root';
    return `${format(date, 'yyyy-MM-dd')}_${assetName}`;
  }


  public generateKey(): string {
    return _.replace('mt' + uuid(), /-/g, '');
  }

  public getMeasurementValueDtoByKey(measurement: MeasurementDto, key: string): MeasurementValueDto {
    return _.find(measurement.measurementValues, (val: MeasurementValueDto) => {
      return val.key === key;
    });
  }

  /**
   * Checks a formControl for errors of type required, rules or equal
   * @param {AbstractControl} formControl
   * @param {string} i18nBase base string for i18n ids
   * @param {string} requiredError i18n postfix that should be used if control has required error
   * @returns {string | boolean} string with i18n code or false if control has no errors
   */
  public getMostRecentErrorText(formControl: AbstractControl, i18nBase: string, requiredError: string): string | boolean {
    if (!i18nBase.endsWith('.')) {
      i18nBase += '.';
    }

    if (formControl.hasError('required')) {
      return i18nBase + requiredError;
    } else if (formControl.hasError('rules')) {
      return i18nBase + 'PASSWORDRULES';
    } else if (formControl.hasError('email')) {
      return i18nBase + 'EMAILINVALID';
    } else if (formControl.hasError('equal')) {
      return i18nBase + 'PASSWORDSDIFFERENT';
    } else {
      return false;
    }
  }

  /**
   * Returns key/label pairs of booleanMeasurementTypeDtos
   * @param measurementType
   */
  public getSectionsFromBooleanMeasurementValueTypeDto(measurementType: MeasurementTypeDto): PhoenixSection[] {
    return _.map(this.gapiconSharedService.getBooleanMeasurementValueTypeDtos(measurementType), (measurementValueTypeDto: MeasurementValueTypeDto) => {
      return new PhoenixSection({
        key: measurementValueTypeDto.key,
        label: measurementValueTypeDto.name,
      });
    });
  }

  /**
   * Calculates a string that can be displayed as a measurement value.
   * @param measurementDto the measurement that should be displayed
   * @param measurementTypeDto the corresponding measurement type (for checklist measurements)
   * @param assetDto optional provide an specific assetDto if the measurement has no measurement. If not provided the asset from the measurement will be used
   */
  public mapMeasurementValuesToMeasurementValue(
    measurementDto: MeasurementDto,
    measurementTypeDto: MeasurementTypeDto,
    assetDto: AssetDto = measurementDto.asset): MeasurementValueDto {
    let measurementValueDto: MeasurementValueDto = new MeasurementValueDto();
    //let measurementValueEnrichedDto: MeasurementValueEnrichedDto = new MeasurementValueEnrichedDto();


    if (_.includes(assetDto.tags, AssetTags.checklist)) {

      const checklistMeasurements: MeasurementValueDto[] = _.filter(measurementDto.measurementValues, (m: MeasurementValueDto) => {
        return m.unit === ValueTypeDto.BOOLEAN || m.unit === 'bool' || m.unit === ValueTypeDto.DECIMAL || m.unit === ValueTypeDto.NUMBER;
      });

      const expectedNumberOfMeasurements: number = _.sumBy(measurementTypeDto.valueTypes, (measurementValueTypeDto: MeasurementValueTypeDto) => {
        return (measurementValueTypeDto.type === ValueTypeDto.BOOLEAN || measurementValueTypeDto.type === ValueTypeDto.NUMBER
          || measurementValueTypeDto.type === ValueTypeDto.DECIMAL) ? 1 : 0;
      });

      measurementValueDto.unit = '/' + expectedNumberOfMeasurements.toString();
      measurementValueDto.value = _.sumBy(checklistMeasurements, (value: MeasurementValueDto) =>
        value.unit === ValueTypeDto.BOOLEAN ? parseInt(value.value, 0) : (value.value != null && value.value != '' ? 1 : 0)).toString();

    } else {
      if (assetDto.measurementPoint.type == "automaticCounter") {
        //measurementValueDto = _.find(measurementDto.measurementValues, { key: 'totalValue' }) || measurementDto.measurementValues[2];
        measurementValueDto = _.find(measurementDto.measurementValues, { key: 'counterValue' }) || measurementDto.measurementValues[0];
        const surveyMethod = _.find(measurementDto.measurementValues, { key: 'surveyMethod' });
        const sensorType = _.find(measurementDto.measurementValues, { key: 'sensorType' });
        if (surveyMethod) {
          measurementValueDto.descriptions.push(surveyMethod.value);
        }
        if (sensorType) {
          measurementValueDto.descriptions.push(sensorType.value);
        }
      } else {
        measurementValueDto = _.find(measurementDto.measurementValues, { key: 'temperatureValue' }) || measurementDto.measurementValues[0];
      }
    }
    return measurementValueDto;
  }

  /**
   * Checks validity of all controls of given FormGroup
   * @param {FormGroup} formGroup that has to be checked
   */
  public validateAllFormGroupMembers(formGroup: UntypedFormGroup): void {
    _.forEach(formGroup.controls, (control: AbstractControl) => {
      control.updateValueAndValidity();
    });
  }

  /**
   * Generates a random password: 
   * length 12, 1 special character, 1 number, 1 lowercase, 1 uppercase
   */
  public generatePassword(): string {
    const length = 12;
    const specialChars = "!@#$%^&*()_+{}[]<>?";
    const numbers = "0123456789";
    const lowerCase = "abcdefghijklmnopqrstuvwxyz";
    const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const allChars = specialChars + numbers + lowerCase + upperCase;

    // Ensure at least one of each required character type
    let password = [
      specialChars[Math.floor(Math.random() * specialChars.length)],
      numbers[Math.floor(Math.random() * numbers.length)],
      lowerCase[Math.floor(Math.random() * lowerCase.length)],
      upperCase[Math.floor(Math.random() * upperCase.length)],
    ];

    // Fill the rest of the password length with random characters
    while (password.length < length) {
      password.push(allChars[Math.floor(Math.random() * allChars.length)]);
    }

    // Shuffle the password to avoid predictable patterns
    password = password.sort(() => Math.random() - 0.5);

    const finalPassword = password.join("");

    // Validate password to ensure no three consecutive characters are the same
    if (/(.)\1\1/.test(finalPassword)) {
      return this.generatePassword(); // Retry if invalid
    }

    return finalPassword;
  }

  public isSafari(): boolean {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  }

  public isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  }

  private convertActionsToString(measurementEnum: Object, measurementValues: MeasurementValueDto[], i18nPrefix: string): string {
    const val: MeasurementValueDto = _.find(measurementValues, (measurementValue: MeasurementValueDto) => {
      return measurementEnum.hasOwnProperty(measurementValue.key) && measurementValue.value === '1';
    });
    return val ? `${i18nPrefix}${val.key}` : undefined;
  }
}
